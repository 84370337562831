import React from 'react';

import Layout from '../marketing/landing/components/common/layout/layout';
import { isSalonzone } from '../utils';
import messages from '../data/messages/en';
import salonzoneMessages from '../data/messages/salonzone/en';

const IndexPage = ({ location }) => (
  <Layout
    location={location}
    locale="en"
    i18nMessages={isSalonzone ? salonzoneMessages : messages}
    isSalonzone={isSalonzone}
  />
);

export default IndexPage;
